import s from "../new-editor/sections/UploadPanel.module.css";
import React, {useCallback, useState} from "react";
import {useDropzone} from "react-dropzone";
import {nanoid} from "nanoid";
import { ReactComponent as CloudUpload } from '@material-design-icons/svg/round/cloud_upload.svg'
import { ReactComponent as CloseIcon } from '@material-design-icons/svg/round/close.svg'
import {useTranslation} from "react-i18next";
import cx from "clsx";
import {Button} from "../Button";
import ms from "./modal.module.css"
import {RemoveBackgroundToggle} from "../new-editor/components/RemoveBackgroundToggle";
export const MODAL_NAME = "multiAssetUpload"

export interface FileWithPreview extends File {
    preview: string
    id: string
}

const MAX_FILES = 9

export const MultiAssetUploadModal = ({onUploadSlides, files, setFiles, close}: any) => {
    const { t } = useTranslation()
    const isScreenDesktop = true;
    const [removeBackground, setRemoveBackoground] = useState(true);

    const handleToggleChange = () => setRemoveBackoground(!removeBackground);

    const clearAndClose = () => {
        setFiles([])
        close()
    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: {
            'image/*': [],
        },
        multiple: true,
        maxFiles: 100, //Limit handled in code
        noDragEventsBubbling: true,
        onDrop: (acceptedFiles) => {
            const limitedAcceptedFiles = acceptedFiles.slice(0, MAX_FILES - files.length)
            setFiles([
                ...files,
                ...limitedAcceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                        id: nanoid(),
                    })
                ),
            ])
        },
    })

    const handleRemove = useCallback((index: number, file: FileWithPreview) => {
        setFiles((prevFiles: any) => {
            const newFiles = prevFiles.filter((_: any, i: any) => i !== index)
            URL.revokeObjectURL(file.preview)
            return newFiles
        })
    }, [])
    const thumbs = files.map((file: any, index: any) => (
        <div className={s.upload_preview} key={file.id}>
            <div className={s.upload_delete} onClick={() => handleRemove(index, file)}>
                <CloseIcon />
            </div>
            <div className={s.upload_inner}>
                <img
                    src={file.preview}
                    className={s.preview_image}
                    onLoad={() => {
                        //URL.revokeObjectURL(file.preview)
                    }}
                />
            </div>
        </div>
    ))
    return (
        <div>
            <h2 className={`${isScreenDesktop ? 'titleS' : 'bodyL'} colorTextBlackPrimary`}>
                Загрузите фотографии
            </h2>
            <div className={cx('colorTextBlackPrimary', 'paragraphM', s.aboutText)}>
                Количество новых слайдов будет равно количеству фотографий
            </div>
            {files.length == 0 && (
            <div
                {...getRootProps({
                    className: cx('dropzone', s.upload_container, { 'drag-active': isDragActive }),
                })}
            >
                <input {...getInputProps()} />
                <CloudUpload className={s.upload_icon} />
                <div className={cx('paragraphM', 'colorTextBlackTertiary')}>
                    {t('pages.editor.dragOrSelectImagesLine1')}
                    <a className={cx(s.upload_link, 'colorAccentVioletDefault')}>
                        {t('pages.editor.dragOrSelectImagesLine2')}
                    </a>
                </div>
            </div>
        )}
            {files.length > 0 && (
                <div className={s.thumbs_container}>
                    {thumbs}
                    {files.length < MAX_FILES && (
                        <div
                            {...getRootProps({
                                className: cx('dropzone', s.upload_button, { 'drag-active': isDragActive }),
                            })}
                        >
                            <input {...getInputProps()} /> <CloudUpload className={s.upload_button_icon} />
                        </div>
                    )}
                </div>
            )}
            <RemoveBackgroundToggle removeBackground={removeBackground} handleToggleChange={handleToggleChange} center />
            <div className={cx(ms.buttons)}>
                <Button
                    type="button"
                    variation="tertiary"
                    size="m"
                    grow={true}
                    onClick={clearAndClose}
                    style={{ marginTop: '12px', width: '107px' }}
                >
                    <div className={s.add_images_button}>
                        <span>Отмена</span>
                    </div>
                </Button>
                <Button
                    type="button"
                    variation="primary"
                    size="m"
                    grow={true}
                    onClick={() => onUploadSlides(removeBackground).then(clearAndClose)}
                    style={{  marginTop: '12px', width: '227px' }}
                >
                    <div className={s.add_images_button}>
                        <span>Добавить</span>
                    </div>
                </Button>
            </div>
        </div>
    )
}