import React, { useState, useEffect } from 'react';
import s from './countdownTimer.module.css'

interface CountdownTimerProps {
  initialSeconds: number
  onComplete?: () => void
}

const CountdownTimer: React.FC<CountdownTimerProps> = (props) => {
  const { initialSeconds, onComplete } = props
  const [seconds, setSeconds] = useState(initialSeconds)

  useEffect(() => {
    if (seconds <= 0) {
      if (onComplete) onComplete()
      return
    }

    const timerId = setInterval(() => {
      setSeconds((prev) => prev - 1);
    }, 1000)

    return () => clearInterval(timerId)
  }, [seconds, onComplete])

  const formatTime = () => {
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const secs = seconds % 60
    return { hours, minutes, seconds: secs }
  }

  const time = formatTime()

  return (
    <div className={s.timerContainer}>

      <div className={s.timeBlock}>
        <span className={s.timeValue}>{String(time.hours).padStart(2, '0')}</span>
        <span className={s.timeLabel}>Часы</span>
      </div>

      <span className={s.colon}>:</span>

      <div className={s.timeBlock}>
        <span className={s.timeValue}>{String(time.minutes).padStart(2, '0')}</span>
        <span className={s.timeLabel}>Минуты</span>
      </div>

      <span className={s.colon}>:</span>

      <div className={s.timeBlock}>
        <span className={s.timeValue}>{String(time.seconds).padStart(2, '0')}</span>
        <span className={s.timeLabel}>Секунды</span>
      </div>
    </div>
  );
};

export default CountdownTimer
