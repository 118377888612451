import s from './productVideo.module.css'
import { reachGoal } from '../../utils/metrics'
import { useEffect } from 'react'
import cx from 'clsx'
import { isRuLocale } from '../../components/new-editor/utils/localization.utils'
import { useTranslation } from 'react-i18next'

export function ProductVideoModal() {
  const { t } = useTranslation()

  useEffect(() => {
    reachGoal('open-video-popup')
  }, [])

  return (
    <div className={s.popupBody}>
      <div style={{ position: 'relative', paddingTop: '56.25%', width: '100% ' }}>
        <iframe
          src={t('pages.videoModal.embedUrl')}
          allow="autoplay; fullscreen; picture-in-picture; encrypted-media; gyroscope; accelerometer; clipboard-write;"
          frameBorder="0"
          allowFullScreen
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: '0',
            left: '0',
            borderRadius: '16px',
          }}
        ></iframe>
      </div>
      <div>
        <h1 className={cx(s.mainTextPopup, 'titleS')}>
            {t('pages.videoModal.title')}
        </h1>
        <div className={cx(s.minorTextPopup, 'paragraphM')}>
          <p>{t('pages.videoModal.afterClose')}</p>
        </div>
      </div>
    </div>
  )
}
