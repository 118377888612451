import s from './modal.module.css'
import image from './popup_upgrade_plan.webp'
import { Button } from '../../components/Button'
import { SharedProps } from '../../components/ModalDialog/ModalDialog'
import cx from 'clsx'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { reachGoal } from '../../utils/metrics'
import { useSubscriptions } from '../../store/useSubscriptions'
import { Trans } from 'react-i18next'

export const MODAL_NAME = 'upgradePlan'

export type UpgradePlanModalProps = {
  onBuyPlanHandler: () => void
}

export function UpgradePlanModal(props: UpgradePlanModalProps & SharedProps) {
  const { close, onBuyPlanHandler } = props
  const { t } = useTranslation()
  const { hasPremiumSubscription, hasBusinessSubscription } = useSubscriptions()

  const onBuyHandler = () => {
    reachGoal('click-upgrade-plan-buy-button')
    close()
    onBuyPlanHandler()
  }

  useEffect(() => {
    reachGoal('open-upgrade-plan-popup')
  },[])

  const getPlanNameKey = () => {
    return hasBusinessSubscription 
      ? 'pages.plans.business'
      :  hasPremiumSubscription 
        ? 'pages.plans.seller'
        : 'pages.plans.beginner'
  }

  return (
    <div className={s.bodyBlackFriday}>
      <img className={cx(s.imgPopup, s.imgPopupPromo)} src={image} />
      <h1 className={cx(s.mainTextPopup, s.promoTitle, 'titleM')}>
        <Trans 
          i18nKey='pages.updateModal.annualPlanDiscountMessage'
          components={{
            b: <b className={s.bloodWeight} />
          }}
        />
        </h1>
      <div className={cx(s.minorTextPopup, s.minorTextBlock, 'paragraphM')}>
        <p className={s.firstMinorText}>
          <Trans 
            i18nKey='pages.updateModal.thankYouPurchaseMessage'
            values={{plan: t(getPlanNameKey())}}
            components={{
              b: <b />,
              span: <span className={s.hideInMobile}></span>
            }}
          />
        </p>
        <p className={s.secondMinorText}>
          <Trans 
            i18nKey='pages.updateModal.upgradeSavingsMessage'
            components={{
              b: <b />
            }}
          />
        </p>
      </div>
      
      <div className={cx(s.popupButtons, s.popupButtonsBlackFridayTitle)}>
        <Button
          className= {s.blackFridayButton}
          size="m"
          grow={false}
          onClick={() => {
              onBuyHandler()
          }}
        >
          {t('pages.updateModal.renewWithDiscount')}
        </Button>
      </div>
    </div>
  )
}
