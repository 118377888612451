import {StoreType} from "polotno/model/store";
import {PaywallVariations, useSubscriptions} from "../../../../store/useSubscriptions";
import {reachGoal} from "../../../../utils/metrics";
import {GenBgAdvicePanel} from "../../../GenBgAdvicePanel";
import {Button} from "../../../Button";
import {useModalManager} from "../../../../store/useModalManager";
import { ReactComponent as ArrowLeft } from '@material-design-icons/svg/round/chevron_left.svg'
import { ReactComponent as DeleteRounded } from '../../delete-rounded.svg'
import {MODAL_NAME as PAYWALL_MODAL} from "../../../../ds/modal/PayWallModal";
import { ReactComponent as PremiumTemplateIcon } from '../../premium_template.svg'
import { useTranslation } from 'react-i18next'
import { deleteTemplate, infoTemplates } from "../../aicard/infographics";
import { useClosePanelInMobile } from '../../../../hooks/useClosePanelInMobile'
import { MODAL_NAME as CONFIRMATION_INFOGRAPHIC } from "../../../../ds/modal/ConfirmationInfographicsModal";
import { useAddInfographics } from "../../../../store/useAddInfographics";
import { useInfographics } from '../../../../hooks/useInfographics'
import { getLocale } from "../../utils/localization.utils";

type Props = {
    store: StoreType,
    isScreenDesktop: boolean
}
export const InfographicsPanel = ({store, isScreenDesktop}: Props) => {
    const toggleModal = useModalManager((s) => s.toggleModal)
    const setPaywallVariation = useSubscriptions(s => s.setPaywallVariation)
    const { t } = useTranslation()
    const closePanelInMobile = useClosePanelInMobile()
    const setOnClickTemplate = useAddInfographics((s) => s.setOnClickTemplate)
    const activeTemplateInState = useAddInfographics((s) => s.activeTemplate)
    const showGenerate = useAddInfographics((s) => s.showGenerate) 
    const setShowGenerate = useAddInfographics((s) => s.setShowGenerate)
    const showConfirmationInfographicsModal = useAddInfographics(s => s.showConfirmationInfographicsModal)
    const { applyInfographics } = useInfographics()

    const showModalOrApplyInfographics = (template: any) => {
        if(showConfirmationInfographicsModal) {
            toggleModal(CONFIRMATION_INFOGRAPHIC, true)
        }else{
            applyInfographics(template)
        }
    }

    const onClickAddInfographics = (template: any, premiumRequired: boolean) => {
        if (premiumRequired) {
            setPaywallVariation(PaywallVariations.templates)
            toggleModal(PAYWALL_MODAL, true)
            return
        }
        setOnClickTemplate(template)
        showModalOrApplyInfographics(template)
        reachGoal('click_infographics_template')
    }

    const onClickDeleteInfographics = () => {
        setOnClickTemplate(deleteTemplate)
        showModalOrApplyInfographics(deleteTemplate)
        reachGoal('click_delete_infographics_template')
    }

    const onBackButtonClick = () =>{
        closePanelInMobile()
    }

    return (
        <div className="tool-panel info_panel">
            <div className="tool-panel_content">
                <div className="tool-panel_content_text">
                    <div className='backButton' onClick={() =>
                        onBackButtonClick()
                    }>
                        <ArrowLeft />
                    </div>
                    <h2 className={`${isScreenDesktop ? 'titleS' : 'bodyL'} colorTextBlackPrimary`}>{t('pages.editor.infographics')}</h2>
                </div>
                {showGenerate && (
                    <div className="tool-panel_about_background">
                        <GenBgAdvicePanel header={t('pages.downloadModal.done')}>
                            <Button
                                size="s"
                                onClick={() => {
                                    store.openSidePanel('ai-background')
                                    setShowGenerate(false)
                                }}
                                style={{ width: '100%', height: '40px' }}
                            >
                                {t('pages.editor.generateBackground')}
                            </Button>
                        </GenBgAdvicePanel>
                    </div>
                )}
                <div className="tool-panel_content_wrapper content_info_wrapper">
                <span className="odyS colorTextBlackPrimary info_text">
                    {t('pages.editor.selectInfographicTemplate')}
                </span>
                    <div className="tool-panel_content_category_wrapper info_wrapper">
                        <div
                            key={deleteTemplate.key}
                            className={
                                activeTemplateInState.key === deleteTemplate.key
                                    ? 'tool-panel_content_template tool-panel_content_template_delete info_elements active'
                                    : 'tool-panel_content_template tool-panel_content_template_delete info_elements'
                            }
                        >
                            <div
                                className="tool-panel_content_template_image delete_info"
                                onClick={onClickDeleteInfographics}
                            >
                                <div className="panel_content_template_delete bodyS">
                                    <DeleteRounded className="infogaphicsTemplate" />
                                    <div className="infogaphicsTemplate">{t('pages.editor.noInfographics')}</div>
                                </div>
                            </div>
                        </div>
                        {infoTemplates.map((template: any) => {
                            const hasPremiumSubscription = useSubscriptions((s) => s.hasPremiumSubscription)
                            const premiumTemplate =  !hasPremiumSubscription && !template.isFree

                            if(template.locale && template.locale !== getLocale()) return
                            return (
                                <div
                                    key={template.key}
                                    className={
                                        activeTemplateInState.key === template.key
                                            ? 'tool-panel_content_template info_elements active'
                                            : 'tool-panel_content_template info_elements'
                                    }
                                    onClick={()=>onClickAddInfographics(template, premiumTemplate)}
                                >
                                    {premiumTemplate && <PremiumTemplateIcon className="premium_icon" />}
                                    <img
                                        className="tool-panel_content_template_image info_img"
                                        src={template.image}
                                        alt={template.alt}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}