import { create } from 'zustand'

export type duplicatedProjectsState = {
  duplicatedProjectId: string | null
}

export type duplicatedProjectsActions = {
  setDuplicatedProjectId: (id: string) => void
  resetDuplicatedProjectId: () => void
}

export const useDuplicatedProjects = create<duplicatedProjectsState & duplicatedProjectsActions>()((set, get) => ({
  duplicatedProjectId: null,
  setDuplicatedProjectId: (val: string) =>
    set({
      duplicatedProjectId: val,
    }),
  resetDuplicatedProjectId: () =>
    set({
      duplicatedProjectId: null,
    }),
}))
