import { PropsWithChildren } from 'react'
import { createTheme, ThemeProvider } from '@mui/material'
import { FeatureFlagProvider } from './contexts/FeatureFlagContext'
import { AuthProvider } from './contexts/AuthProvider'
import { AppLevelModals } from './components/AppLevelModals'
import { FullPageLoadingProvider } from './contexts/FullPageLoadingContext'
import { FullPageLoading } from './components/FullPageLoading'
import { AuthAware } from './auth/AuthAware'
import { CheckVersion } from './components/CheckVersion'
import { getLocale } from './components/new-editor/utils/localization.utils'
import {GoogleOAuthProvider} from "@react-oauth/google";

const theme = createTheme({
  palette: {
    primary: {
      main: '#9f72ff',
    },
    secondary: {
      main: '#7948e1',
    },
  },
  typography: {
    fontFamily: '"Nunito", sans-serif',
  },
})

console.log(`Locale: ${getLocale()}`)

export function App(props: PropsWithChildren) {
  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID!
  console.log(googleClientId, 'GOOOGLEEE')
  return (
    <ThemeProvider theme={theme}>
      <FeatureFlagProvider>
        <GoogleOAuthProvider clientId={googleClientId}>
          <AuthProvider>
            <FullPageLoadingProvider>
              <FullPageLoading />
              <AuthAware />
              <CheckVersion />
              {props.children}
            </FullPageLoadingProvider>
          </AuthProvider>
        </GoogleOAuthProvider>
        {/* Modal dialogs which are available everywhere */}
      </FeatureFlagProvider>
    </ThemeProvider>
  )
}
