import { Button } from '../../Button'
import cx from 'clsx'
import s from './card-plan-redesign.module.css'
import { ReactComponent as CheckIcon } from './tick.svg'
import { ReactComponent as QuestionIcon } from '../question.svg'
import { ReactComponent as DisadvantageIcon } from './cross.svg'
import { ReactComponent as InactiveIcon } from './cross-inactive.svg'
import { useTranslation } from 'react-i18next'
import { getCurrencyFormat } from '../../new-editor/utils/subscription.utils'
import {useEffect, useState} from "react";

export type Feature = {
  label: string
  hint?: string
  disadvantage?: boolean
  active?: boolean
  quantity?: number
}

export type CardPlanProps = {
  title: string
  subTitle?: string
  price: number
  clientTypeTip?: string
  planId?: string
  /**
     Price use as original to show the discount/promocode is applied
     */
  originalPrice: number
  priceDescription?: string
  pricePostfix?: string
  onButtonClick: () => void
  buttonText: string
  highlight?: boolean
  disableButton?: boolean
  moneyFormatter?: (amount: number) => string
  featuresList?: Array<Feature>
  useButtonWithTooltip?: boolean
  btnTooltipText?: string
  /**
     Amount of amount discount in percent
     */
  discountAmount?: number
  checkDiscountAllowed?: (planId?: string) => boolean
  totalBeforeDiscount?: number
}

export function CardPlanRedesign(props: CardPlanProps) {
  const {
    title,
    subTitle,
    price,
    clientTypeTip,
    pricePostfix,
    onButtonClick,
    buttonText,
    priceDescription,
    featuresList = [],
    disableButton = false,
    moneyFormatter = getCurrencyFormat,
    highlight = false,
    useButtonWithTooltip,
    discountAmount,
    originalPrice,
    checkDiscountAllowed,
    planId,
    btnTooltipText,
    totalBeforeDiscount
  } = props

  const { t } = useTranslation()
  const hasDiscount: boolean =
    !!(!!planId && checkDiscountAllowed && !!discountAmount) && checkDiscountAllowed(planId)

  const [isChromeMacOS, setIsChromeMacOS] = useState(false);
  useEffect(() => {
    // Detect Chrome on macOS
    const isMacOS = navigator.userAgent.includes('Macintosh');
    const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

    setIsChromeMacOS(isMacOS && isChrome);
    console.log(isChromeMacOS)
  }, []);

  return (
    <div className={cx(s.CardPlan, { [s.highlighted]: highlight })}>
      {subTitle && <div className={s.SubHeader}>{subTitle}</div>}
      <div className={s.Header}>
        <div>{title}</div>
      </div>

      {clientTypeTip && <div className={cx(s.clientTypeTip, 'bodyS')}>{clientTypeTip}</div>}

      <div className={s.Price}>
        <div>
          {totalBeforeDiscount && (
            <div className={s.BeforePrice}>
              <span className={s.strike}>{moneyFormatter(totalBeforeDiscount)}</span>/год
            </div>
          )}
          {!hasDiscount && <span className={'titleM'}>{moneyFormatter(price)}</span>}
          {hasDiscount && (
            <div className={s.DiscountBlock}>
              {!totalBeforeDiscount && (
                <div className={s.BeforePrice}>
                  <span className={s.strike}>{moneyFormatter(originalPrice)}</span>/{pricePostfix}
                </div>
              )}
              <div className={s.DiscountBlockPrice}>
                <div className={s.DiscountFinalPrice}>
                  <span className={cx('titleM', 'colorAccentVioletGradient')}>
                    {moneyFormatter(price)}
                  </span>
                  {pricePostfix && <span className={s.PricePostfix}>/{pricePostfix}</span>}
                </div>
                <div className={cx(s.DiscountPercentBubble, 'bodyS')}>-{discountAmount}%</div>
              </div>
            </div>
          )}
          {!hasDiscount && pricePostfix && <span className={s.PricePostfix}>/{pricePostfix}</span>}
        </div>

        {priceDescription && <div className={s.PriceDescription}>{priceDescription}</div>}
      </div>

      <div className={s.buttonWrap}>
        <Button
          size="m"
          onClick={onButtonClick}
          className={s.buttonRedesign}
          disabled={disableButton}
        >
          {buttonText}
        </Button>
        {!disableButton && useButtonWithTooltip && (
          <p className={s.tooltipFallback}>{btnTooltipText}</p>
        )}
        {disableButton && useButtonWithTooltip && (
          <p className={s.tooltipFallback}>{btnTooltipText}</p>
        )}
      </div>

      {featuresList && featuresList.length > 0 && <div className={s.Divider} />}

      {featuresList.length > 0 && (
        <div>
          <div className={s.FeaturesList}>
            {featuresList.map((i, ix) => (
              <div key={ix} className={cx(s.Feature, { [s.inactive]: !i.active })}>
                <div className={cx(s.FeatureIcon)}>
                  {i.active ? (
                    i.disadvantage ? (
                      <DisadvantageIcon />
                    ) : (
                      <CheckIcon />
                    )
                  ) : (
                    <InactiveIcon />
                  )}
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: t('pages.plans.' + i.label, { quantity: i.quantity }),
                  }}
                />
                {i.hint && (
                  <div className={s.tooltip}>
                    <QuestionIcon />
                    <div className={cx(s.tooltip_text,{[s.tooltip_not_chrome_macos]: !isChromeMacOS})}>{i.hint}</div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}
