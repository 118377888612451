import {Button} from "../../../Button";
import React, {ChangeEvent, useState} from "react";
import {StoreType} from "polotno/model/store";

import s from "./GoToMultiAsset.module.css"
import { ReactComponent as Cross } from "./cross.svg";
import {Checkbox} from "@mui/material";
import axios from "axios";
import {backendUrl} from "../../../../config/app";
import {PaywallVariations, useSubscriptions} from "../../../../store/useSubscriptions";
import {MODAL_NAME as PAYWALL_MODAL} from "../../../../ds/modal/PayWallModal";
import {useModalManager} from "../../../../store/useModalManager";

type Props = { store: StoreType, crossClick: () => void }

export const GoToMultiAsset = ({store, crossClick}: Props) => {
    const [checked, setChecked] = useState(false)
    const hasPremiumSubscription = useSubscriptions((s) => s.hasPremiumSubscription)
    const setPaywallVariation = useSubscriptions(s => s.setPaywallVariation)
    const toggleModal = useModalManager((s) => s.toggleModal)

    const notDisplay = async (e: ChangeEvent<HTMLInputElement>) => {
        if(!checked) {
            setChecked(true)
            await axios.post(`${backendUrl()}api/hide-multi-asset`)
            crossClick()
        }
    }

    const onGoToMultiAssetClick = () => {
        if (!hasPremiumSubscription) {
            setPaywallVariation(PaywallVariations.multiAsset)
            toggleModal(PAYWALL_MODAL, true)
        } else {
            store.openSidePanel('multi-asset')
        }
    }
    return (
        <div className={s.container}>
            <div className={s.content}>
                <div className={s.headerSection}>
                    <h3 className={s.header}>Готово!</h3>
                    <Cross onClick={crossClick}/>
                </div>
                <div className={s.typography}>
                    Главный слайд завершен. Теперь вы <br/> можете продолжить редактирование или<br/>перейти к созданию фотоворонки <br/> для маркетплейса
                </div>
                <label style={{ display: 'inline-block', lineHeight: '0.2', verticalAlign: 'middle', marginTop: '12px' }}>
                    <Checkbox
                        size="small"
                        checked={checked}
                        style={{ padding: 0, margin: '0 4px 0 0', verticalAlign: 'middle' }}
                        onChange={notDisplay}
                    />
                    <span style={{ verticalAlign: 'middle' }} className={s.checkboxLabel}>
                                        Больше не показывать
                                      </span>
                </label>
                <Button
                    size="m"
                    onClick={onGoToMultiAssetClick}
                    className={s.button}
                >
                    <div className="warning-button-label">
                        Сгенерировать фоны
                    </div>
                </Button>
            </div>
        </div>
    )
}