import { SharedProps } from '../../components/ModalDialog/ModalDialog'
import { useTranslation, Trans } from 'react-i18next'
import cx from 'clsx'
import { Button } from '../../components/Button'
import s from './createNewProjectModal.module.css'
import createNewProjectImage from './create-new-project-modal-image.webp'
import { useResize } from '../../hooks/useResize'
import { useSubscriptions } from '../../store/useSubscriptions'

export const MODAL_NAME = 'createNewProject'

const deleteProjectWarningKey = {
  project: 'deleteProjectWarningWithoutPlan',
  project_10: 'deleteProjectWarningWithPlan',
  project_100: 'deleteProjectWarningWithPlan'
}

export type CreateNewProjectModalProps = {
  onBuyClick: () => void,
  onClickConfirmCreateNewProject: () => void,
}

export function CreateNewProjectModal(props: SharedProps & CreateNewProjectModalProps) {
  const { close, onBuyClick, onClickConfirmCreateNewProject } = props
  const { t } = useTranslation()
  const {isScreenDesktop} = useResize()
  const variation = useSubscriptions(s => s.paywallVariation)

  const getDeleteProjectWarningKey = () => {
    return (
      variation 
        ? deleteProjectWarningKey[variation as keyof typeof deleteProjectWarningKey]
        : 'deleteProjectWarning'
    )
  }

  return (
    <div>
      <img className={s.imgPopup} src={createNewProjectImage} />
      <h1 className={cx(s.mainTextPopup, isScreenDesktop ? 'titleM' : 'bodyL')}>{t('pages.payWallModal.confirmNewProjectCreation')}</h1>
      <div className={cx(s.minorTextPopup, 'paragraphM')}>
        <p>
          <Trans 
            i18nKey={'pages.payWallModal.' + getDeleteProjectWarningKey()} 
            components={{
              b: <b />
            }}
          />
        </p>
      </div>

      <div className={s.popupButtons}>
        <div className={s.footerContainer}>
          <Button size="m" onClick={onClickConfirmCreateNewProject} variation="tertiary">
            {t('pages.payWallModal.confirmButtonText')}
          </Button>
          <Button onClick={onBuyClick} variation="primary" size="m">
            {t('pages.payWallModal.subscribeButtonText')}
          </Button>
        </div>
      </div>
    </div>
  )
}
