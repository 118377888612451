import { PropsWithChildren } from 'react'
import s from './toggle.module.css'
import cx from 'clsx'

export type ToggleSize = 'm' | 's'
export type ToggleProps = {
  isActive?: boolean
  size?: ToggleSize
  onClick?: () => void
}

const getSizeClass = (v: ToggleSize): string => s[`size-${v}`]

export function ToggleElement(props: PropsWithChildren<ToggleProps>) {
  const { isActive, children, size = 's', onClick } = props

  return (
    <div className={cx(s.Tab, getSizeClass(size), { [s.active]: isActive })} onClick={onClick}>
      <div className={s.TabLabel}>{children}</div>
    </div>
  )
}
