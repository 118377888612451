import { PropsWithChildren, createContext, useState, useContext } from 'react'
import {backendUrl} from "../config/app";

export interface AuthContextType {
  hasAuthenticated: boolean
  hasCompletedRegistration: boolean
  isTimeToShowTheVideo: boolean
  marketingConsent?: boolean | null
  userId: number | null
  setUserId: (v: number | null) => void
  setAuthenticated: (state: boolean) => void
  setHasCompletedRegistration: (state: boolean) => void
  setIsTimeToShowTheVideo: (state: boolean) => void
  setMarketingConsent: (state: boolean | null | undefined) => void
  user: User | null,
}

const DEFAULT_VALUE: AuthContextType = {
  hasAuthenticated: false,
  hasCompletedRegistration: true,
  isTimeToShowTheVideo: false,
  marketingConsent: false,
  userId: null,
  setUserId: () => {},
  setAuthenticated: () => {},
  setHasCompletedRegistration: () => {},
  setIsTimeToShowTheVideo: () => {},
  setMarketingConsent: () => {},
  user: null,
}

export interface User {
  id: number,
  isOld: boolean
  hideMultiAssetHint: boolean
}

const AuthContext = createContext(DEFAULT_VALUE)

export function AuthProvider(props: PropsWithChildren) {
  const { children } = props
  const [hasAuthenticated, setAuthenticated] = useState(false)
  const [userId, setStateUserId] = useState<number | null>(null)
  const [hasCompletedRegistration, setHasCompletedRegistration] = useState(true) 
  const [isTimeToShowTheVideo, setIsTimeToShowTheVideo] = useState(false) 
  const [marketingConsent, setMarketingConsent] = useState<boolean | null | undefined>(true) 
  const [user, setUser] = useState<User | null>(null)

  const loadUser = async (id: number) => {
    const res = await fetch(`${backendUrl()}api/user?id=${id}`)
    const user = await res.json()
    setUser(user)
  }

  const setUserId = (id: number | null) => {
    setStateUserId(id)

    typeof id === 'number' && loadUser(id)
  }


  const setAuthenticatedHandler = (v: boolean) => {
    if (!v) {
      setUserId(null)
    }

    setAuthenticated(v)
  }

  const value: AuthContextType = {
    userId,
    hasAuthenticated,
    hasCompletedRegistration,
    isTimeToShowTheVideo,
    marketingConsent,
    setUserId,
    user,
    setAuthenticated: setAuthenticatedHandler,
    setHasCompletedRegistration: setHasCompletedRegistration,
    setIsTimeToShowTheVideo: setIsTimeToShowTheVideo,
    setMarketingConsent: setMarketingConsent
  }
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export function useAuthContext(): AuthContextType {
  return useContext(AuthContext)
}
