import {GoogleLogin} from "@react-oauth/google";
import axios, {AxiosResponse} from "axios";
import {backendUrl} from "../../config/app";
import {useNavigate} from "react-router-dom";
import {useFullPageLoading} from "../../contexts/FullPageLoadingContext";
import {R_START_PAGE, R_THANKS_SIGN_UP} from "../../router-constants";


interface CredentialResponse {
    /** This field is the returned ID token */
    credential?: string;
    /** This field sets how the credential is selected */
    select_by?: 'auto' | 'user' | 'user_1tap' | 'user_2tap' | 'btn' | 'btn_confirm' | 'btn_add_session' | 'btn_confirm_add_session';
    clientId?: string;
}

type TextKey = "signin_with" | "signup_with" | "continue_with" | "signin" | undefined
export type LoginRes = {userId: number, code?: number, status: boolean, registered?: boolean}

export const GoogleWrapper = ({textKey, onLogin}: {textKey: TextKey, onLogin?: (r: LoginRes) => unknown}) => {
    const { setLoading } = useFullPageLoading()
    const navigate = useNavigate()

    const onSuccess = (credentials: CredentialResponse) => {
        setLoading(true)
        axios.post(`${backendUrl()}api/google-registration`, {token: credentials.credential})
            .then(({data}: AxiosResponse) => {
                if(onLogin) return onLogin(data)
                else navigate(R_START_PAGE)
            })
            .catch(console.error)
            .finally(() => setLoading(false))
    }

    return (
        <div>
        <GoogleLogin
            onSuccess={onSuccess}
            onError={() => {
                console.log('Login Failed');
            }}
            width={352}
            shape="pill"
            text={textKey}
        />
    </div>
        )
}