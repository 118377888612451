import cx from "clsx";
import s from "../UploadPanel.module.css";
import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import {Button} from "../../../Button";
import {resizeImage} from "../../../../utils/resizeImage";
import {backendUrl} from "../../../../config/app";
import {useFullPageLoading} from "../../../../contexts/FullPageLoadingContext";
import { useTranslation } from 'react-i18next'
import { ReactComponent as ArrowLeft } from '@material-design-icons/svg/round/chevron_left.svg'
import { useClosePanelInMobile } from '../../../../hooks/useClosePanelInMobile'
import {ModalDialog} from "../../../ModalDialog/ModalDialog";
import {MultiAssetUploadModal, MODAL_NAME as MULTI_ASSET_UPLOAD} from "../../../MultiAssetUploadModal";
import {ReactComponent as UploadIcon} from "./upload.svg";
import {ReactComponent as PlusIcon} from "./plus.svg";
import {useModalManager} from "../../../../store/useModalManager";
import { ReactComponent as AutoFix } from "./autoFixHighRounded.svg";
import {Checkbox} from "@mui/material";
import {StoreType} from "polotno/model/store";
import {createProjectStatParams, getMainObjectDataUrl, getObject} from "../../utils/polotno.utils.js";
import { ReactComponent as DollarSymbol } from '../../dollar_symbol.svg'
import axios, {AxiosResponse} from "axios";
import {ProjectObject} from "../../../../domain/types/Project";
import {useProjects} from "../../../../store/useProjects";
import { ReactComponent as QuestionIcon } from '../../../../components/plans/question.svg'
import {ProductType} from "../AiBackgrounds/types";
import {useCredits} from "../../../../store/useCredits";
import {updateCredits} from "../../../../api/updateCredits";
import {reachGoal} from "../../../../utils/metrics";
import {usePolotno} from "../../../../store/usePolotno";
import {getMainObject} from "../../utils/editor.utils";

type PanelProps = {
    store: any
    addImage: (store: any, imageUrl: string, isNewPage?:boolean) => Promise<void>
    isScreenDesktop: boolean
}

interface FileWithPreview extends File {
    preview: string
    id: string
}

const findInitialProduct = (store: StoreType) => {
    const page = store.pages[0]
    return page && getObject(page, store);
}

const initPictures = (store: StoreType) => {
    return  store.pages
        .map(page => getObject(page, store))
        .filter(object => !!object)
        .map(object => ({src: (object as any)?.src, checked: true, pageId: object!.page.id, store, product: object}))
        .slice(1)
}

type Picture = {src: string, checked: boolean, pageId: string, product: ProductType, store: StoreType}

type GenerateOptions = {
    imageUrl: string,
    prompt: string,
}

const getCardsAiBg = (options: GenerateOptions) => async ({product, store}: Picture) => {
    return axios.post(`${backendUrl()}api/cards/background`,{cardImgUrl: await getMainObjectDataUrl(store, product, true), ...options}
    , {headers: {
        'Content-Type': 'application/json',
    }})
}

async function lastResult(promises: any) {
    if (!promises.length) throw new RangeError("No last result from no promises");
    const results: any[] = [];
    await Promise.all(promises.map((p: any) =>
        p.then((v: any) => {
            results.push(v);
        })
    ));
    return results[results.length-1];
}

const MultiAssetPanel = ({store, addImage, isScreenDesktop}: PanelProps) => {
    const [files, setFiles] = useState<FileWithPreview[]>([])
    const { setLoading } = useFullPageLoading()
    const { t } = useTranslation()
    const closePanelInMobile = useClosePanelInMobile()
    const [isSelectAll, selectAll] = useState(true)
    const toggleModal = useModalManager((s) => s.toggleModal)
    const activeProject: ProjectObject | null = useProjects((s) => s.activeProject)
    const [pictures, setPictures] = useState(initPictures(store))
    const pagesCount = useRef(store.pages.length)
    const { setCredits } = useCredits();
    const product = usePolotno(s => s.product)
    const setProduct = usePolotno(s => s.setProduct)
    const firstProduct = findInitialProduct(store);
    const prompt = firstProduct?.custom?.currentPromptInStore?.prompt;

    useEffect(() => {
        const unsubscribe = store.on('change', () => {
            if(store.pages.length !== pagesCount.current) {
                const pictures = initPictures(store)
                setPictures(pictures)
                pagesCount.current = store.pages.length
            }
        })
        return () => unsubscribe()
    }, [])

    const countSelectedPictures = (pictures: Picture[]) => pictures.filter(p => p.checked).length

    const toggleUpload = () => {
        toggleModal(MULTI_ASSET_UPLOAD, true)
    }

    const onUploadSlides = async (removeBackground: boolean) => {
        if (!files.length) return
        setLoading(true)
        const formData = new FormData()

        for (const file of files) {
            if (file.type === 'image/svg+xml') {
                formData.append('file', file)
            } else {
                const resizedFile = await resizeImage(file, 2400)
                formData.append('file', resizedFile)
            }
        }

        try {
            const response = await fetch(
                `${backendUrl()}api/image/uploadMultiple?removeBackground=${removeBackground}`,
                {
                    method: 'POST',
                    body: formData,
                }
            )

            if (!response.ok) {
                setLoading(false)
                return
            }

            const data = await response.json()
            if (data.images && data.images.length) {
                await store.history.transaction(async () => {
                    for (const imageUrl of data.images) {
                        await addImage(store, imageUrl, true)
                    }
                });
                setPictures(initPictures(store))
                setProduct(getMainObject(store))
            }
            closePanelInMobile()
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.error('Error uploading files:', error)
        }
    }

    const onBackButtonClick = () =>{
        closePanelInMobile()
    }

    const selectAllPictures = (checked: boolean) => {
        const newPictures = pictures.map(p => ({...p, checked}))
        setPictures(newPictures)
    }

    const onSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
        selectAllPictures(e.target.checked)
        selectAll(e.target.checked)
    }

    const generate = async () => {
        setLoading(true)
        const corePage = store.pages.find((p: any) => (getObject(p, store)! as any).src === activeProject?.state.sourceImage)
        const storeCard = await getMainObjectDataUrl(store, getObject(corePage, store), false)
        const selectedPictures = pictures
            .filter(p => p.checked)
            .map(pic => {

                return getCardsAiBg({
                    imageUrl: storeCard,
                    prompt,
                })(pic).then(({data}: AxiosResponse) => {
                    const page = store.pages.find((page: any) => page.id === pic.pageId )

                    if(page) page.set({background: data.url})
                    return data;
                })
            })

        const res = await lastResult(selectedPictures)
        if(res.credits) {
            updateCredits(res.credits, setCredits)
            reachGoal("user_spent_credit", createProjectStatParams(store, product))
        }
        setLoading(false)
    }

    const selectPicture = (picture: Picture) => (e: ChangeEvent<HTMLInputElement>) => {
        const newPictures = pictures.map(p => p.src === picture.src ? {...picture, checked: e.target.checked} : p)
        setPictures(newPictures)
        if(!newPictures.every(p => p.checked)) selectAll(false)
    }

    const generationDisabled = !pictures.length || !prompt || countSelectedPictures(pictures) === 0

    return (
        <div className="tool-panel">
            <div className="tool-panel_content">
                <div className={cx('tool-panel_content_text', s.panel_heading)}>
                    <div className='backButton' onClick={() =>
                        onBackButtonClick()
                    }>
                        <ArrowLeft />
                    </div>
                    <h2 className={`${isScreenDesktop ? 'titleS' : 'bodyL'} colorTextBlackPrimary`}>{t('pages.editor.photoFunnel')}</h2>
                </div>
                <div className={s.content_wrapper}>
                    <span className={cx('colorTextBlackPrimary', 'paragraphM', s.aboutText)}>
                        {t('pages.editor.createSlideSet')}
                    </span>
                    <div>
                        <div className={s.labelBlack}>
                            <UploadIcon/>
                            Загрузка фотографий
                            <div className={s.tooltip}>
                                <QuestionIcon />
                                <div className={cx(s.tooltip_text, s.tooltip_text_reverse)}>Загрузите фотографии продукта в разных ракурсах, и мы автоматически применим инфографику для всех слайдов</div>
                            </div>
                        </div>
                    </div>
                    <Button
                        type="button"
                        variation="tertiary"
                        size="m"
                        grow={true}
                        onClick={toggleUpload}
                        style={{ flexShrink: '0', marginTop: '12px', marginBottom: '24px' }}
                    >
                        <div className={s.add_images_button}>
                                <PlusIcon className={s.plus_icon} />
                                <span>Загрузить фотографии</span>
                        </div>
                    </Button>
                    <div style={{width: "100%"}}>
                        <div>
                            <div className={s.labelBlack}
                            >
                                <AutoFix/>
                                AI фоны
                                <div className={s.tooltip}>
                                    <QuestionIcon />
                                    <div className={cx(s.tooltip_text)}>Отредактируйте расположение продукта на всех слайдах, после чего сгенерируйте AI фоны. Они подстроятся под стиль фона на вашем главном слайде</div>
                                </div>
                            </div>
                            { !prompt && <div className={cx(s.linkContainer)}>
                                Чтобы сгенерировать фоны второстепенным карточкам, сначала задайте фон первому слайду во вкладке <span className={cx(s.link, "colorAccentVioletDefault")} onClick={() => store.openSidePanel('ai-background')}>AI фон</span>
                            </div> }
                            {!!pictures.length && prompt && <div className={s.aiPanel}>
                                <label style={{ display: 'inline-block', lineHeight: '0.2', verticalAlign: 'middle', marginBottom: '16px' }}>
                                    <Checkbox
                                        size="small"
                                        checked={isSelectAll}
                                        onChange={onSelectAll}
                                        style={{ padding: 0, margin: '0 4px 0 0', verticalAlign: 'middle' }}
                                    />
                                    <span style={{ verticalAlign: 'middle' }} className={"bodyM"}>
                                        Выбрать все
                                      </span>
                                </label>
                                <div className={s.imgContainer}>
                                    {pictures.map((picture) => (
                                        <div className={s.aiImagePanel} key={picture.src}>
                                            <img src={picture.src}/>
                                            <Checkbox
                                                size="small"
                                                checked={picture.checked}
                                                onChange={selectPicture(picture)}
                                                style={{ padding: 0, margin: '0 4px 0 0', verticalAlign: 'middle' }}
                                                className={s.aiImageCheckbox}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>

                            }
                            <Button
                                size="m"
                                onClick={() => generate()}
                                disabled={generationDisabled}
                                style={{marginBottom: "10px"}}
                            >
                                <div className="warning-button-label">
                                    Сгенерировать фоны
                                    { countSelectedPictures(pictures) > 0 &&
                                    <div className="warning-button-label-price">
                                        <DollarSymbol style={{ marginTop: '-2px' }} />
                                        <span>{countSelectedPictures(pictures)}</span>
                                    </div>
                                    }
                                </div>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <ModalDialog
                name={MULTI_ASSET_UPLOAD}
                render={(p) => <MultiAssetUploadModal {...p} onUploadSlides={onUploadSlides} files={files} setFiles={setFiles} />}
            />
        </div>
    )
}

export default MultiAssetPanel