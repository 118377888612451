import registerStyles from '../../../auth/register.module.css'
import React from 'react'
import cx from 'clsx'
import styles from './PersonalData.module.css'
import { ReactComponent as YaIcon } from '../../../auth/ya-icon.svg'
import { ReactComponent as GoogleIcon } from '../../../auth/google-icon.svg'
import { ReactComponent as VkIcon } from '../../../auth/vk-icon.svg'
import { Switch, Case } from '../../CondRender/CondRender'
import { ChangePassword } from '../ChangePassword'
import { useTranslation } from 'react-i18next'
import { getEmailPlaceholder } from '../../../utils/auth.utils'

type PersonalDataProps = { user: { email: string; provider: string } }

const providerLabel: Record<string, string> = { vk: 'ВКонтакте', yandex: 'Yandex' }
export const PersonalData = ({ user }: PersonalDataProps) => {
  const { email, provider } = user
  const { t } = useTranslation()
  return (
    <section>
      <div>
        <h4 className={cx('titleS', styles.ProfileBlock)}>{t('pages.profile.loginMethod')}</h4>
        {provider === 'local' && (
          <div className={styles.ProfileBlock}>
            <label className={registerStyles.inputLabel} htmlFor="email">
              E-mail
            </label>
            <input
              className={`${registerStyles.input} bodyM`}
              placeholder={getEmailPlaceholder()}
              type="email"
              id="email"
              value={email}
              disabled
            />
          </div>
        )}
        {provider !== 'local' && (
          <div className={cx(styles.ProviderContainer)}>
            <div className={styles.providerImage}>
              <Switch>
                <Case condition={provider == 'yandex'}>
                  <YaIcon />
                </Case>
                <Case condition={provider == 'vk'}>
                  <VkIcon />
                </Case>
                <Case condition={provider == 'google'}>
                  <GoogleIcon />
                </Case>
              </Switch>
            </div>
            <div className={styles.ProviderDetails}>
              <span className={cx('bodyS', styles.ProviderName)}>{providerLabel[provider]}</span>
              <span className="bodyM">{email}</span>
            </div>
          </div>
        )}
        {provider === 'local' && (
          <div>
            <h4 className={cx('titleS', styles.ProfileBlock)}>{t('pages.profile.changePassword')}</h4>
            <ChangePassword />
          </div>
        )}
      </div>
    </section>
  )
}
