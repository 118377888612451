import { PromoCode } from "../domain/types/PromoCode"
import { checkPromoCode } from "../api/check-promocode"
import { usePromocode } from "../store/usePromocode"
import { useTranslation } from "react-i18next"
import { DAY_IN_MILLISECONDS } from "../components/new-editor/constants/promocode"
import { getActive } from "../components/UserProfile/subscrption-management/SubscriptionHOC"
import { useSubscriptions } from "../store/useSubscriptions"
import { useAuthContext } from "../contexts/AuthProvider"
import { PROLONGATION_USER_ID } from "../components/new-editor/constants/promocode"

export const usePromocodeHook = () => {
    const applyPromocode = usePromocode((s) => s.setPromocode)
    const setError = usePromocode((s) => s.setError)
    const { t } = useTranslation()
    const paymentDateString = localStorage.getItem('paymentDate')
    const shownProlongationModalDateString = localStorage.getItem('shownProlongationModalDate')
    const paymentDate = paymentDateString ? new Date(paymentDateString) : null
    const shownProlongationModalDate = shownProlongationModalDateString ? new Date(shownProlongationModalDateString) : null
    const subscription = getActive(useSubscriptions((s) => s.activeSubscriptions))
    const { userId } = useAuthContext()

    const lookupPlanIds = (v: null | undefined | string[]): null | string[] => {
        if (!v) {
          return null
        }
      
        if (v.length > 0) {
          return v
        }
      
        return null
      }

    const onApplyCodeHandler = async (promocode: string) => {
      const result: PromoCode = await checkPromoCode(promocode)
    
      if (!result.is_valid) {
        setError(t('pages.promocode.invalidPromoCode'))
        return
      }
    
      applyPromocode(result.code!, result.percent!, lookupPlanIds(result.plans_ids))
    }

    const isMonthlySubscription = () => {
      return subscription?.subscriptionType === "MONTHLY"
    }

    const isShowUpgardePlanElements = () => {
      return (paymentDate && Math.abs(new Date().getTime() - paymentDate.getTime()) <= DAY_IN_MILLISECONDS && isMonthlySubscription())
    }

    const isShowProlongationElements = () => {
      return (shownProlongationModalDate && Math.abs(new Date().getTime() - shownProlongationModalDate.getTime()) <= DAY_IN_MILLISECONDS && isMonthlySubscription())
    }

    const isShowProlongationModal = () => {
      return userId ? PROLONGATION_USER_ID.includes(userId) : false
    }

    const secondsForUpgradeTimer = () => {
      return paymentDate ? Math.floor((paymentDate.getTime() + DAY_IN_MILLISECONDS - new Date().getTime())/1000) : 0
    }

    const secondsForPrologationTimer = () => {
      return shownProlongationModalDate ? Math.floor((shownProlongationModalDate.getTime() + DAY_IN_MILLISECONDS - new Date().getTime())/1000) : 0
    }

    return {
      onApplyCodeHandler,
      isShowUpgardePlanElements,
      secondsForUpgradeTimer,
      isShowProlongationModal,
      isShowProlongationElements,
      secondsForPrologationTimer,
    }
}

export default usePromocodeHook