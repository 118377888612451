import { create } from 'zustand'
import {useContext} from "react";
import {FeatureFlagContext} from "../contexts/FeatureFlagContext";

type Plan = 'yearly' | 'monthly' | 'shop'

export type PlanState = {
  planTab: Plan
}

export type PlanActions = {
  setPlanTab: (plan: Plan) => void
  resetPlanTab: () => void
}

export const usePlans = create<PlanState & PlanActions>()((set) => {
  const DEFAULT_PLAN_TAB: Plan = 'monthly'

  return {
    planTab: DEFAULT_PLAN_TAB,
    resetPlanTab: () =>
      set(() => ({
        planTab: DEFAULT_PLAN_TAB,
      })),
    setPlanTab: (plan: Plan) =>
      set(() => ({
        planTab: plan,
      })),
  }
})
