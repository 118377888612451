import s from './modal.module.css'
import image from './popup_new_card.png'
import { Button } from '../../components/Button'
import { SharedProps } from '../../components/ModalDialog/ModalDialog'
import cx from 'clsx'
import { useTranslation } from 'react-i18next'
import { Toggle } from '../../components/new-editor/components/Toggle'
import style from './confirmationInfographicsModal.module.css'
import { useAddInfographics } from '../../store/useAddInfographics'

export const MODAL_NAME = 'confirmationInfographic'

export type ConfirmationInfographicModalProps = {
  onConfirmationInfographics: () => void
}

export function ConfirmationInfographicModal(props: ConfirmationInfographicModalProps & SharedProps) {
  const { close, onConfirmationInfographics } = props
  const { t } = useTranslation()
  const showConfirmationModal = useAddInfographics(s => s.showConfirmationInfographicsModal)
  const setShowConfirmationInfographicsModal = useAddInfographics(s => s.setShowConfirmationInfographicsModal)

  const OnConfirmationHandler = () => {
    onConfirmationInfographics()
  }

  const handleToggleChange = () => {
    setShowConfirmationInfographicsModal(!showConfirmationModal)
    localStorage.setItem('hideConfirmationInfographicsModal', (showConfirmationModal).toString())
  }

  return (
    <div>
      <img className={s.imgPopup} src={image} />
      <h1 className={cx(s.mainTextPopup, 'titleM')}>{t('pages.confirmationInfographicModal.applyTemplate')}</h1>
      <div className={cx(s.minorTextPopup, style.minorTextPopupInConfirmation, 'paragraphM')}>
        <p>
          {t('pages.confirmationInfographicModal.confirmTemplateChange')}
        </p>
      </div>
      <Toggle toggleChecked={!showConfirmationModal} handleToggleChange={handleToggleChange} labelText={t('pages.confirmationInfographicModal.dontAskAgain')}/>
      <div className={s.popupButtons}>
        <Button size="m" variation="tertiary" grow={false} onClick={close}>
          {t('pages.confirmationInfographicModal.cancelAction')}
        </Button>
        <Button
          size="m"
          grow={false}
          onClick={() => {
              close()
              OnConfirmationHandler()
          }}
        >
          {t('pages.confirmationInfographicModal.applyAction')}
        </Button>
      </div>
    </div>
  )
}
