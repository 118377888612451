import cx from 'clsx'
import s from '../../../src/components/ModalDialog/ModalDialog.module.css'
import { ReactComponent as CrossIcon } from './icon-cross.svg'
import { PropsWithChildren } from 'react'

export type ModalProps = {
  close: () => void
}

export const ModalComponent = (props: PropsWithChildren<ModalProps>) => {
  const { close, children } = props
  
  const onCloseHandler = () => {
    close()
  }

  return (
    <div>
      <div className={cx(s.dialog)} onClick={onCloseHandler}>
        <div className={cx(s.inner_wrapper)} onClick={(event) => event.stopPropagation()}>
          <div className={s.top_dock}>
            <div className={cx(s.cursor_pointer, s.close)} onClick={onCloseHandler}>
              <CrossIcon />
            </div>
          </div>
          <div className={cx(s.container)}>
            <div className={cx(s.body)}>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
