import s from './modal.module.css'
import image from './popup_download_image.png'
import { Button } from '../../components/Button'
import { reachGoal } from '../../utils/metrics'
import { useEffect } from 'react'
import { SharedProps } from '../../components/ModalDialog/ModalDialog'
import cx from 'clsx'
import { useTranslation, Trans } from 'react-i18next'
import { ReactComponent as DollarSymbol } from './dollar_symbol_color.svg'


export const MODAL_NAME = 'contact_modal'

export type ContactModalProps = {
  onBuyClick: () => void
}

export function ContactModal(props: ContactModalProps & SharedProps) {
    const { onBuyClick, close } = props
    useEffect(() => reachGoal('open_contact_popup'), [])

    const { t } = useTranslation()

    const onContactButtonClick = () => {
      reachGoal('click_contact_button_on_contact_popup')
      close()
      window.open('https://t.me/Pavel_MPcard?text=' + t('pages.downloadModal.startingMessage'), '_blank')
    }
    const onBuyCreditsClick = () => {
      reachGoal('click_buy_credits_on_contact_popup')
      onBuyClick()
      close()
    }

    return (
    <div>
      <img className={s.imgPopup} src={image} />
      <h1 className={cx(s.mainTextPopup, 'titleM')}>{t('pages.payWallModal.unlimitedDownloads')}</h1>
      <div className={cx(s.minorTextPopup, 'paragraphM')}>
        <p>
          <Trans 
            i18nKey='pages.downloadModal.unlockDownloads'
            components={{
              b: <b />
            }}
          />
        </p>
      </div>
      <div className={s.popupButtons}>
        <Button
          size="m"
          variation="tertiary"
          grow={false}
          onClick={() => {
            close()
            onBuyCreditsClick()
          }}
        >
          <div className={s.buttonLabel}>
            <DollarSymbol style={{ width: '24px', height: '24px' }} />
            {t('pages.downloadModal.goToPlans')}
          </div>
        </Button>
        <Button
          size="m"
          grow={false}
          onClick={() => {
            onContactButtonClick()
          }}
        >
          {t('pages.downloadModal.getFree')}
        </Button>
      </div>
    </div>
    )
}
