import { PropsWithChildren } from 'react'
import s from './toggle.module.css'
export type ToggleGroupProps = {}

export function PlansToggle(props: PropsWithChildren<ToggleGroupProps>) {
  const { children } = props

  return <div className={s.TabContainer}>
    <div className={s.toggleWrapper}>
      {children}
    </div>
  </div>
}
