import { ReactComponent as GradientRounded } from '@material-design-icons/svg/round/gradient.svg'
import { FormControlLabel, Switch } from '@mui/material'
import cx from 'clsx'
import s from './Toggle.module.css'

interface ToggleProps {
  labelText: string,
  toggleChecked: boolean,
  handleToggleChange: () => void
}

export const Toggle: React.FC<ToggleProps> = ({ toggleChecked, handleToggleChange, labelText }) => {

  const toggleHandler = () => {
    handleToggleChange()
  }

  return(
    <div className={cx('bodyM', s.Toggle)}>
        <FormControlLabel
          control={<Switch checked={toggleChecked} onChange={toggleHandler} />}
          className={s.toggle}
          label={<span className={cx(s.toggleLabel, 'bodyM')}>{labelText}</span>}
          labelPlacement="end"
        />
    </div>
  )} 