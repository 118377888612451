import { observer } from 'mobx-react-lite'
import React from 'react'
import { onSnapshot } from 'mobx-state-tree'
import { Button, Navbar, Popover, Menu, MenuItem, Position } from '@blueprintjs/core'
import { Play, Plus, Pause } from '@blueprintjs/icons'
import { ReactSortable } from 'react-sortablejs'
import { flags } from 'polotno/utils/flags'
import styled from 'polotno/utils/styled'
import { StoreType } from 'polotno/model/store'
import { PageType } from 'polotno/model/page-model'
import { AudioType } from 'polotno/model/audio-model'
import { t } from 'polotno/utils/l10n'
import { deepEqual } from 'polotno/utils/deep-equal'

const OuterPagesButtonsContainer = styled('div')`
  position: relative;
  height: 0px;
`

const PagesButtonsContainer = styled('div')`
  position: absolute;
  bottom: 5px;
  width: auto;
  left: 5px;
  overflow: hidden;
  box-shadow: 0 0 4px lightgrey;
  border-radius: 5px;
  z-index: 1;
`

const PagePreviewContainer = styled('div', React.forwardRef)`
  display: flex;
  position: relative;
  border-radius: 15px;

  &:hover {
    .polotno-page-menu {
      opacity: 1;
      pointer-events: auto;
    }
  }
`

const AudioContainer = styled('div')`
  position: relative;

  &:hover {
    .polotno-audio-menu {
      opacity: 1;
      pointer-events: auto;
    }
  }
`

const PageMenu = styled('div')`
  position: absolute;
  z-index: 20;
  top: 5px;
  right: 5px;
  opacity: 0;
  pointer-events: none;

  &:hover {
    display: block;
  }
`

const Spinner = styled('div')`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #09f;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`

const AudioMenu = styled('div')`
  position: absolute;
  z-index: 20;
  top: -5px;
  right: 8px;
  opacity: 0;
  pointer-events: none;

  &:hover {
    display: block;
  }
`

// Queue system for page preview generation
// This is required to prevent performance issues caused by generating multiple previews simultaneously.
// By processing previews one at a time, we reduce CPU and memory usage
// also it blocks less "waitLoading" of the store.
let previewQueue: { page: PageType; setPreview: (src: string) => void }[] = []
let isProcessingQueue = false

const processQueue = async () => {
  if (isProcessingQueue || previewQueue.length === 0) return

  isProcessingQueue = true
  // @ts-ignore
  const { page, setPreview } = previewQueue.shift()

  try {
      const src = await page.store.toDataURL({
          pageId: page.id,
          pixelRatio: 0.1,
          quickMode: true,
      })
      setPreview(src)
  } catch (e){
      console.error('error generating preview',e)
  }

  isProcessingQueue = false
  processQueue()
}

const Page = observer(({ page, scale }: { page: PageType; scale: number }) => {
  const [preview, setPreview] = React.useState(null)
  const isActive = page.store.activePage === page
  const store = page.store
  const visibleRef = React.useRef(false)

  React.useEffect(() => {
    const updatePreview = () => {
      // @ts-ignore
      previewQueue.push({ page, setPreview })
      processQueue()
    }

    let debounceTimeout: string | number | NodeJS.Timeout | null | undefined = null
    let forceUpdateTimeout: string | number | NodeJS.Timeout | null | undefined = null
    let lastUpdateTime = Date.now()

    const requestUpdatePreview = () => {
      // Clear existing debounce timeout
      if (debounceTimeout) {
        clearTimeout(debounceTimeout)
      }

      // do not update preview if page is not visible
      if (!visibleRef.current) {
        return
      }

      // Set force update timeout - will trigger after 6s regardless
      // useful if we delayed debounce for too long
      if (!forceUpdateTimeout) {
        forceUpdateTimeout = setTimeout(() => {
          if (Date.now() - lastUpdateTime >= 6000) {
            updatePreview()
            lastUpdateTime = Date.now()
            forceUpdateTimeout = null
          }
        }, 6000)
      }

      // Set debounce timeout - will only trigger if no changes for 300ms
      debounceTimeout = setTimeout(() => {
        updatePreview()
        lastUpdateTime = Date.now()
        debounceTimeout = null
        if (forceUpdateTimeout) {
          clearTimeout(forceUpdateTimeout)
          forceUpdateTimeout = null
        }
      }, 300)
    }

    let oldSnapshot: {} | null = null
    const disposer = onSnapshot(page, (snapshot) => {
      if (!deepEqual(oldSnapshot, snapshot)) {
        requestUpdatePreview()
        oldSnapshot = snapshot
      }
    })

    const handleIntersection = (entries: any[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          visibleRef.current = true
          requestUpdatePreview()
        } else {
          if (debounceTimeout) {
            clearTimeout(debounceTimeout)
          }
          if (forceUpdateTimeout) {
            clearTimeout(forceUpdateTimeout)
          }
          visibleRef.current = false
        }
      })
    }

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.1,
    })
    if (groupRef.current) {
      observer.observe(groupRef.current)
    }

    return () => {
      observer.disconnect()
      if (debounceTimeout) {
        clearTimeout(debounceTimeout)
      }
      if (forceUpdateTimeout) {
        clearTimeout(forceUpdateTimeout)
      }
      disposer()
      // Remove this page from the queue if component unmounts
      previewQueue = previewQueue.filter((item) => item.page !== page)
    }
  }, [])

  const groupRef = React.useRef(null)

  const handleStartDrag = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, type: string) => {
    e.preventDefault()
    const handleMove = (e: { preventDefault?: any; clientX?: any }) => {
      e.preventDefault()
      // offset is distance between border and dragger
      const absOffset = 7
      const offset = type === 'start' ? absOffset : -absOffset
      const { clientX } = e
      // @ts-ignore
      const { left, width } = groupRef.current.getBoundingClientRect()
      const time = (clientX - left - offset) / width
      if (type === 'start') {
        // page.set({
        //   startTime: Math.min(page.endTime, Math.max(0, time)),
        // });
      } else if (type === 'end') {
        page.set({
          duration: Math.max(1000, time * page.duration),
        })
      }

      // const duration = await getVideoDuration(src);
      // const ratio = (clientX - offsetLeft) / offsetWidth;
      // const newDuration = Math.round(duration * ratio);
      // element.duration = newDuration;
    }
    window.addEventListener('mousemove', handleMove)
    window.addEventListener('mouseup', () => {
      window.removeEventListener('mousemove', handleMove)
    })
  }

  const height = 60
  const pageWidth = (height / page.computedHeight) * page.computedWidth
  const width = flags.animationsEnabled ? page.duration * scale : pageWidth
  const hasManyPages = store.pages.length > 1

  return (
    <PagePreviewContainer
      style={{
        width: width + 'px',
        marginRight: flags.animationsEnabled ? '0px' : '10px',
        height: height + 'px',
      }}
      ref={groupRef}
      className="polotno-page-container"
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          borderRadius: '15px',
          backgroundImage: preview ? `url("${preview}")` : 'none',
          backgroundRepeat: 'repeat-x',
          backgroundSize: 'auto 100%',
          backgroundColor: 'white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          // border: isActive
          //   ? '2px solid rgb(0, 161, 255, 0.9)'
          //   : '2px solid transparent',
        }}
        onClick={() => {
          page.store.selectPage(page.id)
        }}
      >
        {!preview && <Spinner />}
      </div>

      <div
        style={{
          position: 'absolute',
          top: '0',
          left: '0px',
          bottom: '0px',
          right: '0px',
          borderRadius: '15px',
          border: isActive ? '2px solid rgb(0, 161, 255, 0.9)' : '2px solid lightgrey',
          zIndex: 1,
          pointerEvents: 'none',
        }}
      />
      {flags.animationsEnabled && (
        <div
          style={{
            position: 'absolute',
            zIndex: 1,
            bottom: '5px',
            left: '5px',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            color: 'white',
            padding: '2px 7px',
            textAlign: 'center',
            borderRadius: '4rem',
          }}
        >
          {(page.duration / 1000).toFixed(1)}s
        </div>
      )}
      {flags.animationsEnabled && (
        <div
          style={{
            position: 'absolute',
            zIndex: 1,
            top: '50%',
            right: 0 + 'px',
            width: '8px',
            height: '50%',
            transform: 'translateY(-50%) translateX(-3px)',
            borderRadius: '5px',
            border: '1px solid rgb(255, 255, 255, 0.6)',
            backgroundColor: 'rgb(0, 0, 0, 0.6)',
            cursor: 'ew-resize',
          }}
          onMouseDown={(e) => {
            handleStartDrag(e, 'end')
          }}
        ></div>
      )}
      <PageMenu
        className="polotno-page-menu"
        onClick={(e: any) => {
          e.stopPropagation()
        }}
      >
        <Popover
          content={
            <Menu style={{ width: '100px' }}>
              <MenuItem
                icon="duplicate"
                text={t('pagesTimeline.duplicatePage')}
                onClick={() => {
                  page.clone()
                }}
              />
              {hasManyPages && (
                <MenuItem
                  icon="trash"
                  text={t('pagesTimeline.removePage')}
                  onClick={() => {
                    page.store.deletePages([page.id])
                  }}
                />
              )}
            </Menu>
          }
          position={Position.TOP}
        >
          <Button icon="more" style={{ minHeight: '20px', borderRadius: '10px' }} />
        </Popover>
      </PageMenu>
      {/* <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '0px',
          width: '8px',
          height: '50%',
          transform: 'translateY(-50%) translateX(3px)',
          borderRadius: '5px',
          backgroundColor: 'rgb(0, 161, 255, 0.9)',
          cursor: 'ew-resize',
        }}
        onMouseDown={(e) => {
          handleStartDrag(e, 'start');
        }}
      ></div> */}
    </PagePreviewContainer>
  )
})

const CurrentTime = observer(({ store, scale }: { store: StoreType; scale: number }) => {
  const time = store.isPlaying ? store.currentTime : store.activePage?.startTime || 0
  return (
    <div
      style={{
        position: 'absolute',
        left: time * scale + 'px',
        top: '-5px',
        width: '2px',
        height: 'calc(100% + 10px)',
        backgroundColor: 'rgb(0, 161, 255, 0.9)',
      }}
    ></div>
  )
})

export const Pages = observer(({ store, scale }: { store: StoreType; scale: number }) => {
  const handleChange = (items: { id: any }[]) => {
    items.forEach(({ id }, newIndex) => {
      const page = store.pages.find((p) => p.id === id)
      // @ts-ignore
      const oldIndex = store.pages.indexOf(page)
      if (oldIndex !== newIndex) {
        // @ts-ignore
        page.setZIndex(newIndex)
      }
    })
  }

  const list = store.pages.map((p) => ({ id: p.id }))
  return (
    <>
      <ReactSortable
        list={list}
        setList={handleChange}
        direction="horizontal"
        style={{ display: 'flex', flexDirection: 'row' }}
        delay={500}
        delayOnTouchOnly
        className="polotno-pages-container"
        // on mobile limit drag to only handler, otherwise it's impossible to scroll
        // handle={isMobileDevice ? '.drag-handle' : undefined}
      >
        {list.map(({ id }) => {
          const page = store.pages.find((p) => p.id === id)

          // @ts-ignore
          return <Page page={page} scale={scale} key={id} />
        })}
      </ReactSortable>
    </>
  )
})

const Audio = observer(
  ({
    audio,
    scale,
    store,
    index,
  }: {
    audio: AudioType
    scale: number
    store: StoreType
    index: number
  }) => {
    const maxRight = store.duration * scale
    const maxWidth = maxRight - audio.delay * scale
    const width = Math.min((audio.endTime - audio.startTime) * audio.duration * scale, maxWidth)
    const left = audio.delay * scale

    const handleMove = (e: React.MouseEvent) => {
      const startX = e.clientX
      const startLeft = left

      const handleMouseMove = (e: MouseEvent) => {
        const diff = (e.clientX - startX) / scale
        const newStartTime = Math.max(0, startLeft / scale + diff)
        const actialDiff = newStartTime - audio.delay
        audio.set({
          delay: newStartTime,
        })
      }

      const handleMouseUp = () => {
        window.removeEventListener('mousemove', handleMouseMove)
        window.removeEventListener('mouseup', handleMouseUp)
      }

      window.addEventListener('mousemove', handleMouseMove)
      window.addEventListener('mouseup', handleMouseUp)
    }

    const handleResize = (e: React.MouseEvent, edge: 'start' | 'end') => {
      e.stopPropagation()
      const startX = e.clientX
      const startLeft = left
      const startWidth = width

      const handleMouseMove = (e: MouseEvent) => {
        e.preventDefault()
        const diff = (e.clientX - startX) / scale
        if (edge === 'start') {
          const newStartTime = Math.max(0, startLeft / scale + diff)
          audio.set({
            delay: newStartTime,
          })
        } else {
          const newEndTime = Math.max(audio.startTime + 1, (startLeft + startWidth) / scale + diff)
          audio.set({
            endTime: newEndTime,
          })
        }
      }

      const handleMouseUp = () => {
        window.removeEventListener('mousemove', handleMouseMove)
        window.removeEventListener('mouseup', handleMouseUp)
      }

      window.addEventListener('mousemove', handleMouseMove)
      window.addEventListener('mouseup', handleMouseUp)
    }

    return (
      <AudioContainer
        style={{
          position: 'absolute',
          left: `${left}px`,
          top: `${index * 10}px`,
          width: `${width}px`,
          height: '10px',
          backgroundColor: 'rgba(0, 161, 255, 0.5)',
          borderRadius: '10px',
          cursor: 'move',
        }}
        onMouseDown={handleMove}
        className="polotno-audio-container"
      >
        <div
          style={{
            position: 'absolute',
            left: '0',
            top: '0',
            width: '10px',
            height: '100%',
            cursor: 'ew-resize',
          }}
          onMouseDown={(e) => handleResize(e, 'start')}
        />
        <div
          style={{
            position: 'absolute',
            right: '0',
            top: '0',
            width: '10px',
            height: '100%',
            cursor: 'ew-resize',
          }}
          onMouseDown={(e) => handleResize(e, 'end')}
        />
        <AudioMenu
          className="polotno-audio-menu"
          onClick={(e: any) => {
            e.stopPropagation()
          }}
        >
          <Popover
            content={
              <Menu style={{ width: '100px' }}>
                <MenuItem
                  icon="trash"
                  text={t('pagesTimeline.removeAudio')}
                  onClick={() => {
                    store.removeAudio(audio.id)
                  }}
                />
              </Menu>
            }
            position={Position.TOP}
          >
            <Button
              icon="more"
              style={{
                minHeight: '20px',
                borderRadius: '10px',
                padding: '0px',
              }}
            />
          </Popover>
        </AudioMenu>
      </AudioContainer>
    )
  }
)

const Audios = observer(({ store, scale }: { store: StoreType; scale: number }) => {
  return (
    <div
      style={{
        position: 'absolute',
        bottom: '-25px',
        paddingTop: '5px',
      }}
      className="polotno-audios-container"
    >
      {store.audios.map((audio, index) => (
        <Audio key={audio.id} audio={audio} scale={scale} store={store} index={index} />
      ))}
    </div>
  )
})

const formatDuration = (timeInMs: number) => {
  const minutes = Math.floor(timeInMs / 60000)
  const seconds = Math.floor((timeInMs % 60000) / 1000)
  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
}

const PlayButtonContainer = styled('div')`
  position: absolute;
  top: 5px;
  left: 5px;
  padding-right: 8px;
  z-index: 2;
  width: 100px;
  text-align: center;
`

const PlayButton = observer(({ store }: { store: StoreType }) => {
  return (
    <PlayButtonContainer className="polotno-play-button-container">
      <Button
        icon={store.isPlaying ? <Pause size={25} /> : <Play size={25} />}
        onClick={() => {
          if (store.isPlaying) {
            const activePage = store.activePage
            store.stop()
            if (activePage) {
              store.selectPage(activePage.id)
            }
          } else {
            store.play({
              startTime: store.activePage?.startTime || 0,
            })
          }
        }}
        style={{
          width: '60px',
          height: '60px',
          borderRadius: '50px',
        }}
      ></Button>
      <div style={{ paddingTop: '5px' }}>
        {formatDuration(store.currentTime)} / {formatDuration(store.duration)}
      </div>
    </PlayButtonContainer>
  )
})

export const PagesTimeline = observer(
  ({ store, defaultOpened = false }: { store: StoreType; defaultOpened?: boolean }) => {
    // defaultOpened = defaultOpened ?? flags.animationsEnabled;
    const scale = 1 / 50
    const [panelOpened, setPanelOpened] = React.useState(defaultOpened)

    const togglePanel = () => {
      setPanelOpened(!panelOpened)
    }

    const height = flags.animationsEnabled ? store.audios.length * 10 + 90 : 90
    return (
      <>
        <OuterPagesButtonsContainer>
          <PagesButtonsContainer>
            <Navbar style={{ height: '35px', padding: '0 5px' }}>
              <Navbar.Group style={{ height: '35px' }}>
                <Button
                  minimal
                  onClick={togglePanel}
                  icon={flags.animationsEnabled && !panelOpened ? 'play' : null}
                >
                  {t('pagesTimeline.pages')}
                </Button>
              </Navbar.Group>
            </Navbar>
          </PagesButtonsContainer>
        </OuterPagesButtonsContainer>
        <Navbar
          style={{
            padding: '5px',
            height: 'auto',
            zIndex: 1,
            // here we have two options:
            // 1. unmount the component when closing
            // 2. keep the component mounted but hidden
            //
            // 1. is simpler but not cache for pages previews (especially noticable on many pages)
            // 2. a bit clunky as we have large component mounted but hidden
            //
            // I chose option 2 for now as it's simpler
            display: panelOpened ? 'block' : 'none', // Hide instead of unmount
          }}
          className="polotno-pages-timeline"
        >
          <div
            style={{
              width: '100%',
              position: 'relative',
              height: height,
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                overflowX: 'auto',
                padding: '5px',
                zIndex: 1,
                display: 'flex', // Always flex, visibility controlled by parent
              }}
            >
              <div style={{ height: '60px', display: 'flex' }}>
                {flags.animationsEnabled && (
                  <div
                    style={{
                      width: '90px',
                      paddingRight: '5px',
                      paddingLeft: '60px',
                    }}
                  ></div>
                )}
                <div style={{ position: 'relative' }}>
                  <Pages store={store} scale={scale} />

                  {flags.animationsEnabled && <CurrentTime store={store} scale={scale} />}
                  {flags.animationsEnabled && <Audios store={store} scale={scale} />}
                </div>
                <Button
                  icon={<Plus />}
                  style={{ width: '60px' }}
                  onClick={() => {
                    store.addPage({
                      bleed: store.activePage?.bleed || 0,
                    })
                  }}
                  minimal
                ></Button>
              </div>
            </div>

            {flags.animationsEnabled && <PlayButton store={store} />}
          </div>
        </Navbar>
      </>
    )
  }
)
