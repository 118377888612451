import { create } from 'zustand'
import { deleteTemplate } from '../components/new-editor/aicard/infographics'

const getDefaultTemplate = () =>{
  return deleteTemplate
}

const getDefaultShowConfirmationInfographicsModal = () =>{
  return localStorage.getItem('hideConfirmationInfographicsModal') !== 'true'
}

export type State = {
  activeTemplate: any,
  onClickTemplate: any,
  showGenerate: boolean,
  showConfirmationInfographicsModal: boolean,
}

export type Actions = {
  setActiveTemplate: (value: any) => void,
  setOnClickTemplate: (value: any) => void,
  setShowGenerate: (value: boolean) => void,
  setShowConfirmationInfographicsModal: (value: boolean) => void,
}

export const useAddInfographics = create<State & Actions>()((set) => ({
  activeTemplate: getDefaultTemplate(),
  onClickTemplate: null,
  showGenerate: false,
  showConfirmationInfographicsModal: getDefaultShowConfirmationInfographicsModal(),
  setActiveTemplate: (value: any) =>
    set((state) => ({
      activeTemplate: value
    })),
  setOnClickTemplate: (value: any) =>
    set((state) => ({
      onClickTemplate: value
    })),
  setShowGenerate: (value: boolean) =>
    set((state) => ({
      showGenerate: value
    })),
  setShowConfirmationInfographicsModal: (value: boolean) =>
    set((state) => ({
      showConfirmationInfographicsModal: value
    })),
}))
