import { useState } from 'react'
import { fetchProjects } from '../api/projects/fetch-projects'
import { useProjects } from '../store/useProjects'

export const useProjectHook = () => {
  const [isLoading, setLoading] = useState(false)
  const { setProjects } = useProjects()

  const fetchProjectsFn = async (showLoader = false) => {
    if (showLoader) {
      setLoading(true)
    }
    const projectsArray = await fetchProjects()
    setProjects(projectsArray)
    if (showLoader) {

      setLoading(false)
    }
  }

  return fetchProjectsFn
}

export default useProjectHook