import React, { useState, useEffect } from 'react'
// import { useAuth, AuthContextType } from '../contexts/AuthContext'
import styles from './register.module.css'
import { Button } from '../components/Button'
import { useFullPageLoading } from '../contexts/FullPageLoadingContext'
import authClient from './AuthClient'
import { SocialBlock } from './SocialBlock'
import { useSocialAuthHandler } from './useSocialAuthHandler'
import { HeaderSeparated } from '../components/headers/HeaderSeparated'
import cx from 'clsx'
import { useNavigate } from 'react-router-dom'
import { R_SIGN_UP_PAGE, R_FORGOT_PASSWORD_PAGE, R_START_PAGE } from '../router-constants'
import { reachGoal, sendUserId } from '../utils/metrics'
import { sendUtmData } from '../logger/source_saver'
import { useAuthContext } from '../contexts/AuthProvider'
import { useCredits } from '../store/useCredits'
import { fetchCredits } from '../api/fetchCredits'
import { useSubscriptions } from '../store/useSubscriptions'
import { fetchActiveSubscriptions } from '../api/fetchActiveSubscriptions'
import { useModalManager } from '../store/useModalManager'
import { PaymentNotificationDto, getNotification, unsetNotification } from '../api/notifications'
import {
  MODAL_NAME_SUBSCRIPTION,
  MODAL_NAME as PAID_MODAL,
} from '../ds/modal/SuccessfullyPaidModal'
import { useTranslation } from 'react-i18next'
import { getTranslateKey } from './getTranslateKey'
import { getEmailPlaceholder } from '../utils/auth.utils'
import {GoogleWrapper, LoginRes} from "./social/GoogleWrapper";
import {isEnLocale} from "../components/new-editor/utils/localization.utils";
import { MODAL_NAME as UPGARDE_PLAN } from '../ds/modal/UpgradePlanModal'
import { isMonthlySubscription } from '../components/new-editor/utils/promocode.utils'

const isSocialAuthEnabled = process.env.REACT_APP_ENABLE_SOCIAL_AUTH == 'true'

//! Must be same for backend and frontend
const MAGIC_1 = 989
const MAGIC_2 = 4096

const validateErrorToken = (num: number | null): boolean => {
  if (!num) {
    return false
  }
  const number = new Date().getDay()
  const calculated = (num - MAGIC_2) / MAGIC_1

  return number == calculated
}

const Login: React.FC<{}> = () => {
  // const { login, signInError, setSignInErrMsg }: AuthContextType = useAuth()
  const [signInError, setSignInErrMsg] = useState<string | null>(null)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const { setLoading, isLoading } = useFullPageLoading()
  const [socialAuthError, setSocialAuthError] = useState<string | null>(null)
  const yandexAuthHandler = useSocialAuthHandler('yandex')
  const { setAuthenticated, setUserId, hasAuthenticated } = useAuthContext()
  const vkAuthHandler = useSocialAuthHandler('vk')
  const navigate = useNavigate()
  const setCredits = useCredits((s) => s.setCredits)
  const toggleModal = useModalManager((s) => s.toggleModal)
  const setSubscriptions = useSubscriptions((s) => s.setActiveSubscriptions)
  const hasErrorState = (): [boolean, null | number] => {
    // Get the query string from the URL
    const queryString = window.location.search

    // Create a URLSearchParams object from the query string
    const urlParams = new URLSearchParams(queryString)

    // Check if 'action' parameter exists and equals 'login'
    if (urlParams.has('state') && urlParams.get('state') === 'error') {
      const code = urlParams.get('code') ?? '0'
      return [true, parseInt(code)]
    }

    return [false, null]
  }
  const { t } = useTranslation()

  useEffect(() => {
    if (hasAuthenticated) {
      navigate(R_START_PAGE)
    }
  }, [hasAuthenticated])

  //! refactor: move to utils - this function is really helpful
  function clearQueryString() {
    // Get the current URL without the query string
    const url = window.location.protocol + '//' + window.location.host + window.location.pathname

    // Use history.replaceState to update the URL without reloading the page
    window.history.replaceState({ path: url }, '', url)
  }

  const processNotifications = async () => {
    const data: Array<PaymentNotificationDto> = await getNotification()
    const notificationsToUnset: Promise<void>[] = []
    if (data.length > 0) {
      data.forEach((n) => {
        if (n.notification_type == 'regular_payment_succeeded' || n.notification_type == null) {
          if (n.details) {
            try {
              const pDetails: any = JSON.parse(n.details)
              if (pDetails.creditsBought) {
                reachGoal(`purchase_generations_${pDetails.creditsBought}`, {
                  order_price: pDetails.sum,
                })
              } else {
                // fallback
                reachGoal(`purchase_generations`)
              }
            } catch (e) {
              console.error(e)
            }
          }

          toggleModal(PAID_MODAL, true)
        }

        if (n.notification_type == 'subscription_payment_succeeded') {
          reachGoal('credits-purchase')
          if (n.details) {
            try {
              const pDetails: any = JSON.parse(n.details)
              const planType = pDetails.plan.type
              if (pDetails.plan) {
                window.PURCHASE_PLAN = t('pages.plans.' + getTranslateKey(pDetails.plan))
                reachGoal(`purchase_plan_${pDetails.plan.name}`, { order_price: pDetails.sum })
                if (pDetails.isUpgrade) {
                  reachGoal(`upgrade_plan_to_${pDetails.plan.name}`) 
                }
              } else {
                // fallback
                reachGoal(`purchase_plan`)
              }

              if(isMonthlySubscription(planType)){
                const currentDate = new Date();
                localStorage.setItem('paymentDate', currentDate.toString())
                toggleModal(UPGARDE_PLAN, true)
              }else{
                toggleModal(MODAL_NAME_SUBSCRIPTION, true)
              }

            } catch (e) {
              console.error(e)
            }
          }
        }

        notificationsToUnset.push(unsetNotification(n.id))
      })
    }
    if (notificationsToUnset.length > 0) {
      await Promise.allSettled(notificationsToUnset)
    }
  }

  useEffect(() => {
    const [status, code] = hasErrorState()
    if (status) {
      const isErrorCode = validateErrorToken(code)
      if (isErrorCode) {
        setSocialAuthError(t('pages.enterScreen.authError'))
      }
    }

    return () => {
      if (status) {
        clearQueryString()
      }
    }
  }, [])

  const onLogin = async ({status, code, userId, registered}: LoginRes) => {
    setLoading(false)
    if (status) {
      if (registered) {
        reachGoal('user_registered')
      }
      reachGoal('user_logged_in')
      sendUtmData()
      setUserId(userId)
      setAuthenticated(true)
      sendUserId(userId)
      const sbs = await fetchActiveSubscriptions()
      setSubscriptions(sbs)
      await processNotifications()

      const credits: number | null = await fetchCredits()
      if (credits !== null) {
        setCredits(credits)
      }

      navigate(R_START_PAGE)
    } else {
      if (code) {
        if (code === 4001) {
          setSignInErrMsg(t('pages.enterScreen.invalidCredentials'))
          return
        }
        setSignInErrMsg(t('pages.enterScreen.authenticationError'))
      }
    }
  }
  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault()
    setSignInErrMsg(null)
    try {
      setLoading(true)
      const loginRes = await authClient.signIn({ email: username, pwd: password })
      await onLogin(loginRes)
    } catch (error) {
      setLoading(false)
      setSignInErrMsg(t('pages.enterScreen.invalidCredentials'))

      console.error(error)
    }
  }

  const createAuthHandler = (provider: 'vk' | 'yandex') => async () => {
    setSocialAuthError(null)
    try {
      if (provider === 'yandex') {
        await yandexAuthHandler()
      }
      if (provider === 'vk') {
        await vkAuthHandler()
      }
    } catch (error) {
      console.log('error', error)
      setSocialAuthError(t('pages.enterScreen.authenticationError'))
    }
  }

  const goSignUp = () => navigate(R_SIGN_UP_PAGE)
  const goForgotPassword = () => navigate(R_FORGOT_PASSWORD_PAGE)

  return (
    <div className={styles.upload}>
      <div className={styles.content}>
        <div className={styles.title}>
          <h2 className="titleL colorTextBlackPrimary">{t('pages.enterScreen.login')}</h2>
          <div className={styles.other}>
            <span className="paragraphM colorTextBlackSecondary">{t('pages.enterScreen.noAccountYet')}</span>
            <Button onClick={goSignUp} size="s" variation="tertiary" grow={false}>
              {t('pages.registerScreen.signUp')}
            </Button>
          </div>
        </div>

        { isEnLocale() && <>
          <GoogleWrapper textKey="signin_with" onLogin={onLogin} />
          <div style={{ padding: '0 7%', width: '100%' }}>
            <HeaderSeparated>{t('pages.registerScreen.or')}</HeaderSeparated>
          </div>
        </>}

        {isSocialAuthEnabled && (
          <>
            <div style={{ width: '100%' }}>
              <SocialBlock
                onYandexClick={createAuthHandler('yandex')}
                onVkClick={createAuthHandler('vk')}
              />
            </div>

            <div style={{ padding: '0 7%', width: '100%' }}>
              <HeaderSeparated>{t('pages.registerScreen.or')}</HeaderSeparated>
            </div>
          </>
        )}
        <form className={styles.form} onSubmit={handleLogin}>
          <div className={styles.inputContainer}>
            <input
              placeholder={getEmailPlaceholder()}
              className={cx(styles.input, 'bodyM')}
              type="text"
              id="login"
              value={username}
              onChange={(e) => {
                setUsername(e.target.value)
                setSignInErrMsg(null)
              }}
              disabled={isLoading}
              required
            />
          </div>
          <div className={styles.inputContainer}>
            <input
              placeholder={t('pages.enterScreen.password')}
              className={cx(styles.input, 'bodyM')}
              type="password"
              id="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value)
                setSignInErrMsg(null)
              }}
              disabled={isLoading}
              required
            />
          </div>
          {signInError && (
            <p className={`paragraphS colorAccentPinkDark ${styles.errorText}`}>{signInError}</p>
          )}
          <Button type="submit" size="m" disabled={isLoading}>
          {t('pages.registerScreen.login')}
          </Button>
        </form>
        <span
          onClick={goForgotPassword}
          style={{ cursor: 'pointer' }}
          className={`bodyM colorAccentVioletDefault ${styles.forgotPassword}`}
        >
          {t('pages.enterScreen.forgotPassword')}
        </span>
      </div>
    </div>
  )
}

export default Login
