import { create } from 'zustand'

export type FileState = {
  files: File[] | null
  removeBackground: boolean
}

export type FileActions = {
  setFiles: (files: File[]) => void
  resetFiles: () => void
  getFiles: () => File[] | null
  setUseRemoveBackground: (removeBackground: boolean) => void
  getUseRemoveBackground: () => boolean
}

export const useFiles = create<FileState & FileActions>()((set, get) => ({
  files: null,
  removeBackground: true,
  setFiles: (val: File[]) =>
    set((state) => ({
      ...state,
      files: val,
    })),
  resetFiles: () =>
    set(() => ({
      files: null,
    })),
  getFiles: () => {
    const { files } = get()
    return files ? files : null
  },
  setUseRemoveBackground: (val: boolean) =>
    set((state) => ({
      ...state,
      removeBackground: val,
    })),
  getUseRemoveBackground: () => {
    const { removeBackground } = get()
    return removeBackground
  },
}))
