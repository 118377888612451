import { FullWideLayout } from '../components/layouts/FullWideLayout'
import Header from '../components/Header/Header'
import { useNavigate } from 'react-router-dom'
import { PlansContainer } from '../components/plans/PlansContainer'
import useFeatureFlag from "../hooks/useFeatureFlag";
import {useContext} from "react";
import {FeatureFlagContext} from "../contexts/FeatureFlagContext";
import {PlansContainerRedesign} from "../components/plans/PlansContainerRedesign";
import {useResize} from "../hooks/useResize";

export function PlansPage() {
  const navigate = useNavigate()
  const {isAB}  = useContext(FeatureFlagContext)
  const {isScreenDesktop} = useResize()
  const handleGoBack = () => navigate(-1)
  return (
    <FullWideLayout header={<Header />}>
      {(isAB('annual_plans_focus') && isScreenDesktop) ?
          (<PlansContainerRedesign onClickBack={handleGoBack}/>) :
          (<PlansContainer onClickBack={handleGoBack} />) }
    </FullWideLayout>
  )
}
