import {PaywallVariations, useSubscriptions} from "../store/useSubscriptions";
import {MODAL_NAME as PAYWALL_MODAL} from "../ds/modal/PayWallModal";
import {getProjectId} from "../logger/logEvent";
import {createProjectStatParams} from "../components/new-editor/utils/polotno.utils.js";
import {backendUrl} from "../config/app";
import {reachGoal} from "../utils/metrics";
import {useModalManager} from "../store/useModalManager";
import {useAuthContext} from "../contexts/AuthProvider";
import {useImageStore} from "../store/useImageStore";
import {usePolotno} from "../store/usePolotno";
import { isRuLocale } from "../components/new-editor/utils/localization.utils";
import { MODAL_NAME as CONTACT_MODAL } from "../ds/modal/ContactAfterDownloadClickModal";
import { useCredits } from "../store/useCredits";
import { hasCredits } from "../components/new-editor/utils/subscription.utils";
import { contactModalRuEnabled } from "../components/new-editor/constants/download";
import { contactModalEnEnabled } from "../components/new-editor/constants/download";
import { numberOfCreditsAfterDownloadWork } from "../components/new-editor/constants/download";

const getActivePageBlob = async (store: any) => {
    return await store.toBlob({
        pageId: store.activePage.id,
        ignoreBackground: false,
        mimeType: 'image/jpeg',
    })
}

const shouldShowContactModal = () => {
    return isRuLocale() ? contactModalRuEnabled : contactModalEnEnabled
}

export const useDownload = () => {
    const toggleModal = useModalManager((s) => s.toggleModal)
    const { user } = useAuthContext()
    const hasActiveSubscription = useSubscriptions(s => s.hasActiveSubscription)
    const setPaywallVariation = useSubscriptions(s => s.setPaywallVariation)
    const currentGenerator = useImageStore(s => s.currentSelected)
    const product = usePolotno(s => s.product)
    const store = usePolotno(s => s.store)
    const credits = useCredits((s) => s.credits)

    const isLessThanFourCredits = () => {
        return (credits !== null && (hasCredits(credits) || credits === 0) && credits <= numberOfCreditsAfterDownloadWork)
    }

    const onDownloadClick = () => {
        if ( !hasActiveSubscription && !user?.isOld && isLessThanFourCredits()) {
            if(shouldShowContactModal()){
                toggleModal(CONTACT_MODAL, true)
            } else{
                setPaywallVariation(PaywallVariations.download)
                toggleModal(PAYWALL_MODAL, true)
            }
        } else {
            openPopupDownload()
        }
    }

    const openPopupDownload = async (): Promise<void> => {
        for (let element of store.activePage.children) {
            element.set({ showInExport: true }) //ensure no elements stuck in hidden state
        }
        await store.waitLoading()
        const dataUrl = await getActivePageBlob(store)
        const formData = new FormData()
        formData.append('file', dataUrl, 'image.jpg')
        const currentBackgroundType = product?.custom?.activebackgroundType

        const params = {
            pid: getProjectId(),
            generator: currentGenerator,
            ...createProjectStatParams(store, product)
        }
        const paramsQuery = new URLSearchParams(params);
        let storeResultUrl = `${backendUrl()}api/image/store-result?${paramsQuery.toString()}`

        fetch(storeResultUrl, {method: 'POST', body: formData})
        reachGoal(`click_download_result ${currentBackgroundType}`,
            params)
        toggleModal('downloadImage', true)
        store.saveAsImage({ fileName: 'MPcard-' + new Date().getTime(), pageId: store.activePage.id, pixelRatio:2 })
    }

    return { onDownloadClick }
}